import React, { Component } from "react";
import { Link, navigate } from "gatsby";
import { getUserToken, sendEmailActivation, checkActivationEmail } from "../services/Auth";
import { Button, notification, Row, Col, Modal } from 'antd';
import { RadiusUprightOutlined } from '@ant-design/icons';
import $ from "jquery"

class Activation extends Component {

    constructor(props, context) {

        super(props, context);
        this.params = props.location.search.split('?email=')
        this.email = (this.params[1] !== undefined ? this.params[1] : '')

    }

    componentDidMount() {

        if (this.email == '') navigate(this.props.loginUrl);

        if (getUserToken() == null) navigate(this.props.loginUrl);

    }

    async reSendEmailConfirmation() {

        try {

            const { message, status_code } = await sendEmailActivation();

            if (status_code == 200114) {
                notification.success({
                    message: "SUCCESS",
                    description: message,
                    placement: 'topRight',
                })
            }

            else if (status_code == 200) {

                navigate(this.props.redirectUrl);
            }

            else {

                notification.error({
                    message: "ERROR",
                    description: message,
                    placement: 'topRight',
                })
            }
        } catch (error) {
            console.log(error)
        }


    }

    render() {

        const description = this.props.langData.description.split('%member_mail%')

        return (

            <>

                <div className="reg container container--500">
                    <h1 className="text--center">{this.props.langData.title}</h1>

                    <p className="p--sm text--center mart--xxl marb--xxl">
                        {this.props.langKey == 'en' ? description[0] + ' ' + this.email : description[0] + ' ' + this.email + ' ' + description[1]}
                    </p>

                    <p className="p--sm text--center mart--xxl marb--xxl">
                        {this.props.langData.laber_for_resend_mail}
                        <a onClick={this.reSendEmailConfirmation} className="send__mail">
                            {this.props.langData.label_link_resend_mail}
                        </a>
                    </p>
                </div>
            </>
        )
    }

}

export default Activation